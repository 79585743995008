// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-content-page-js": () => import("./../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-product-page-js": () => import("./../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-request-page-js": () => import("./../src/templates/request-page.js" /* webpackChunkName: "component---src-templates-request-page-js" */),
  "component---src-templates-reseller-page-js": () => import("./../src/templates/reseller-page.js" /* webpackChunkName: "component---src-templates-reseller-page-js" */),
  "component---src-templates-usecases-page-js": () => import("./../src/templates/usecases-page.js" /* webpackChunkName: "component---src-templates-usecases-page-js" */)
}

